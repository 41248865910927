export function fetchWithRetry(url, options, retries = 3, delay = 1000) {
  return fetch(url, options).catch((error) => {
    if (retries === 0) {
      throw error;
    }
    console.log("Retrying fetch for...", url);
    // Use setTimeout to retry after the specified delay
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchWithRetry(url, options, retries - 1, delay));
      }, delay);
    });
  });
}
