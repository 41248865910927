import { createContext, useEffect, useState } from "react";
import { NextRouter, useRouter } from "next/router";
import { PageType } from "./baseTypes";

/**
 * Language Context For Global State
 * @returns context of the provider in the whole application
 */
export const LanguageContext = createContext({
  toggle: "de",
  setToggle: (toggle: string) => {},
});

/**
 * Language Provider Wrapped Around Application
 */
export const LanguageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { toggle, setToggle } = useToggle();

  return (
    <LanguageContext.Provider value={{ toggle, setToggle }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useToggle = () => {
  const router = useRouter();
  const [lang, setLang] = useState<string>(router.locale ?? "de");

  useEffect(() => {
    if (router.locale) {
      setLang(router.locale);
    }
  }, [router.locale]);

  const setToggle = (lang: string) => {
    if (typeof window !== "undefined") {
      document.documentElement.lang = lang;
      if (router.locale) {
        setLang(router.locale);
      }
    }
  };

  return { toggle: lang, setToggle };
};

/**
 * **Path Translator**
 * @returns *available translations for the current page*
 */
export const pathTranslator = (page: PageType) => {
  const availableTranslations: { lang: string; path: string }[] = [];

  if (page) {
    if (page.wpml_translations) {
      page.wpml_translations.map((info) => {
        availableTranslations.push({
          lang: info.locale.split("_")[0],
          path: dynamicPath(info.href),
        });
      });
    }
  }

  return availableTranslations;
};

export const dynamicPath = (href: string) => {
  const url = href.split("/");
  if (url[url.length - 1] !== "") {
    // Url has no / at the end
    return url[url.length - 1];
  } else {
    // Url has / at the end
    return url[url.length - 2];
  }
};

export const getWPMLTranslationsForPage = (
  router: NextRouter,
  page?: PageType
) => {
  const { locale } = router;
  const activeLocale = locale;
  const pageType = page?.type !== "page" && page?.type;

  return page?.wpml_translations && page?.wpml_translations.length
    ? page?.wpml_translations
        .filter((translation: any) => translation.locale !== activeLocale)
        .map((translation: any) => translation.href.replace("/" + pageType, ""))
    : [""];
};
