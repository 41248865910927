import { getUrl } from "../utils/url";
import { SearchWikiType } from "../utils/baseTypes";
import { dynamicParamUrl } from "./getWiki";
import { fetchWithRetry } from "../utils/fetchWithRetry";

export async function getSearch(
  keyword: string,
  postsPerPage?: number,
  page?: number,
  lang?: string,
  defaultLang?: string
): Promise<SearchWikiType> {
  const dynamicParams = dynamicParamUrl(postsPerPage, page);
  const searchRes = await fetchWithRetry(
    `${getUrl(
      lang,
      defaultLang
    )}/aunds/search/?keyword=${keyword}${dynamicParams}`
  );
  return (await searchRes.json()) as Promise<SearchWikiType>;
}
