import { trailingSlash, i18n } from "../../next.config";

export const BASE_URL_API =
  process.env.WP_API_URL?.slice(-1) === "/"
    ? `${process.env.WP_API_URL.slice(0, -1)}`
    : process.env.WP_API_URL;

export const getUrl = (lang?: string, defaultLang?: string, v1?: boolean) => {
  if (v1) {
    return lang && defaultLang && lang !== defaultLang
      ? `${BASE_URL_API}/${lang}/wp-json`
      : BASE_URL_V1;
  } else {
    return lang && defaultLang && lang !== defaultLang
      ? `${BASE_URL_API}/${lang}/wp-json/wp/v2`
      : BASE_URL;
  }
};

export const BASE_URL_V1 = `${BASE_URL_API}/wp-json`;
export const BASE_URL_V3 = `${BASE_URL_API}/wp-json/acf/v3`;
export const BASE_URL = `${BASE_URL_API}/wp-json/wp/v2`;

export const getRelativePathFromLink = (
  url: string,
  urlType: string = "default"
) => {
  if (validURL(url) && urlType !== "custom") {
    return new URL(url).pathname;
  }
  return url;
};

export const getPathNameArray = (url: string) => {
  const path = getRelativePathFromLink(url);
  return path.split("/").filter((el) => el !== "");
};

// function validURL(str: string) {
//   const pattern = new RegExp(
//     "^(https?:\\/\\/)?" + // protocol
//       "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
//       "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
//       "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
//       "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
//       "(\\#[-a-z\\d_]*)?$",
//     "i"
//   ); // fragment locator
//   return !!pattern.test(str);
// }
const optimizeInternalUrl = (href: string) => {
  if (href.startsWith("/" + i18n.defaultLocale)) {
    href = href.replace("/" + i18n.defaultLocale, "");
  }
  const isTrailingSlash = href.startsWith("#") ? false : trailingSlash;
  return `${href.replace(/\/$/, "")}${isTrailingSlash ? "/" : ""}`;
};

function validURL(str: string) {
  try {
    new URL(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const setTrailingSlash = (url: string) => {
  return `${url.replace(/\/$/, "")}${trailingSlash ? "/" : ""}`;
};

//checks whether an link is internal and absolute
export const isInternalLinkAbsolute = (url: string) => {
  if (!process.env.NEXT_PUBLIC_PUBLIC_URL) {
    throw new Error(
      "NEXT_PUBLIC_PUBLIC_URL not set but indispensable for isInternalLinkAbsolute"
    );
  }
  if (!validURL(process.env.NEXT_PUBLIC_PUBLIC_URL)) {
    throw new Error(
      "NEXT_PUBLIC_PUBLIC_URL " +
        process.env.NEXT_PUBLIC_PUBLIC_URL +
        " is no valid url"
    );
  }
  if (
    validURL(url) &&
    new URL(url).hostname ===
      new URL(process.env.NEXT_PUBLIC_PUBLIC_URL).hostname
  ) {
    return true;
  }
  return false;
};

/**
 * is intended to optimize all kinds of urls
 * currently does following:
 * - internal absolute urls are converted to relative ones
 * - trailing slash is applied or removed according to next.config.js to prevent 3xx errors
 *
 * @param url url to optimize
 * @returns optimized url
 */
export const optimizeURL = (url: string) => {
  if (!url) return;
  if (url === "/") return url;
  if (isInternalLinkAbsolute(url)) {
    const relativeURL = getRelativePathFromLink(url);
    return optimizeInternalUrl(relativeURL);
  }
  if (!validURL(url)) {
    //assume relative url
    return optimizeInternalUrl(url);
  }
  return url;
};
