import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { getWikiPerLetter } from "../hooks/getWiki";
import { InlineLinkType, ToolTipType } from "../utils/baseTypes";
import { AzFilterPostType } from "../utils/baseTypes";
import MouseFollower from "../components/MouseFollow/MouseFollower";
import useUpdateEffect from "../hooks/useUpdateEffect";

export interface IContextType {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  searchPosts: AzFilterPostType;
  setSearchPosts: Dispatch<SetStateAction<AzFilterPostType>>;
  azFilterPosts: AzFilterPostType;
  setAzFilterPosts: Dispatch<SetStateAction<AzFilterPostType>>;
  azFilter: string;
  setAzFilter: Dispatch<SetStateAction<string>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  wikiPostsPerPage: number;
  setWikiPostsPerPage: Dispatch<SetStateAction<number>>;
  initialLoading: boolean;
  setInitialLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;

  showInlineNav: boolean;
  setShowInlineNav: Dispatch<SetStateAction<boolean>>;
  inlineNavInView: boolean;
  setInlineNavInView: Dispatch<SetStateAction<boolean>>;
  inlineLinks: InlineLinkType[];
  setInlineLinks: Dispatch<SetStateAction<InlineLinkType[]>>;

  // for divider transition
  sectionAnimation: boolean;
  setSectionAnimation: Dispatch<SetStateAction<boolean>>;
  sectionBGTxt: string;
  setSectionBGTxt: Dispatch<SetStateAction<string>>;
  sectionClamp: number;
  setSectionClamp: Dispatch<SetStateAction<number>>;
  setSectionBG: Dispatch<SetStateAction<string>>;
  sectionBG: string;
  setDividerTop: Dispatch<SetStateAction<number>>;
  dividerTop: number;
  setContextCursor: Dispatch<SetStateAction<MouseFollower | undefined>>;
  contextCursor: MouseFollower | undefined;
}

const AppContext = createContext<IContextType | null>(null);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [showInlineNav, setShowInlineNav] = useState<boolean>(false);
  const [inlineNavInView, setInlineNavInView] = useState<boolean>(false);
  const [inlineLinks, setInlineLinks] = useState<InlineLinkType[]>([]);

  // for divider transition
  const [sectionAnimation, setSectionAnimation] = useState<boolean>(false);
  const [sectionBGTxt, setSectionBGTxt] = useState<string>("");
  const [sectionBG, setSectionBG] = useState<string>("");
  const [sectionClamp, setSectionClamp] = useState<number>(369);
  const [dividerTop, setDividerTop] = useState<number>(369);

  //#region SEARCH AND WIKI
  // Search
  const [search, setSearch] = useState("");
  const [searchPosts, setSearchPosts] = useState<AzFilterPostType>({
    postsTotal: 0,
    posts: [],
    filteredChar: null,
  });

  // a-z filter
  const [azFilterPosts, setAzFilterPosts] = useState<AzFilterPostType>({
    postsTotal: 0,
    posts: [],
    filteredChar: null,
  });
  const [azFilter, setAzFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [wikiPostsPerPage, setWikiPostsPerPage] = useState<number>(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [contextCursor, setContextCursor] = useState<MouseFollower>();

  useUpdateEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      if (azFilter !== "all") {
        const wikiData = await getWikiPerLetter(
          azFilter,
          wikiPostsPerPage,
          currentPage
        );
        // update global state here on context
        setAzFilterPosts(wikiData);
      } else {
        // update global state here on context
        const wikiData = await getWikiPerLetter(
          null,
          wikiPostsPerPage,
          currentPage
        );
        setAzFilterPosts(wikiData);
      }
      setLoading(false);
    };
    if (!initialLoading) {
      fetchPosts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <AppContext.Provider
      value={{
        search,
        setSearch,
        searchPosts,
        setSearchPosts,
        azFilterPosts,
        setAzFilterPosts,
        azFilter,
        setAzFilter,
        showInlineNav,
        setShowInlineNav,
        inlineNavInView,
        setInlineNavInView,
        inlineLinks,
        setInlineLinks,
        currentPage,
        setCurrentPage,
        wikiPostsPerPage,
        setWikiPostsPerPage,
        initialLoading,
        setInitialLoading,
        loading,
        setLoading,

        // for divider transition
        sectionAnimation,
        setSectionAnimation,
        setSectionBGTxt,
        sectionBGTxt,
        setSectionClamp,
        sectionClamp,
        setSectionBG,
        sectionBG,
        setDividerTop,
        dividerTop,

        // for mouse follow
        setContextCursor,
        contextCursor,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext<IContextType | null>(AppContext);
  if (context === undefined || context === null) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useAppContext };
