import Head from "next/head";
import { LanguageProvider } from "../utils/language";
import { AppProvider } from "../context/AppContext";
import "../../src/style.scss";
import { AnimatePresence } from "framer-motion";
import { AppProps } from "next/app";
import { usePreserveScroll } from "../utils/usePreserveScroll";
import { SearchProvider } from "../context/SearchContext";
import { useEffect } from "react";
import { googleoffNextData } from "../utils/googleoff";
import { useScriptInjection } from "@aleksundshantu.com/aunds-cookie-plugin-react";

function MyApp({ Component, pageProps }: AppProps) {
  usePreserveScroll();
  useScriptInjection(process.env.WP_API_URL!);

  useEffect(() => {
    googleoffNextData();
  }, []);
  return (
    <>
      <LanguageProvider>
        <Head>
          {/* you can add metadata here, for all pages */}
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <AnimatePresence>
          <AppProvider>
            <SearchProvider>
              <Component {...pageProps} />
            </SearchProvider>
          </AppProvider>
        </AnimatePresence>
      </LanguageProvider>
    </>
  );
}

export default MyApp;
