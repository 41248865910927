const googleon = () => document.createComment("googleon: all");
const googleoff = () => document.createComment("googleoff: all");

export const insertNodeBefore = (refNode: Node, insertNode: Node) =>
  refNode.parentNode?.insertBefore(insertNode, refNode);
export const insertNodeAfter = (refNode: Node, insertNode: Node) =>
  refNode.parentNode?.insertBefore(insertNode, refNode.nextSibling);
export const surroundNode = (refNode: Node, before: Node, after: Node) => {
  insertNodeBefore(refNode, before);
  insertNodeAfter(refNode, after);
};

const waitFor = async (id: string, waitTimeMs = 500) => {
  let el: ReturnType<typeof document.getElementById>;
  while ((el = document.getElementById(id)) == null)
    await new Promise((res) => setTimeout(res, waitTimeMs));
  return el;
};

export const googleoffNextData = async () => {
  const nextData = await waitFor("__NEXT_DATA__");
  surroundNode(nextData, googleoff(), googleon());
};
