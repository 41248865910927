import { getUrl } from "../utils/url";
import { AzFilterPostType, WikiType } from "../utils/baseTypes";
import { fetchWithRetry } from "../utils/fetchWithRetry";

export async function getWikiPerLetter(
  letter: string | null,
  postsPerPage?: number,
  page?: number,
  lang?: string,
  defaultLang?: string
): Promise<AzFilterPostType> {
  const dynamicParams = dynamicParamUrl(postsPerPage, page);
  const letterParam = letter ? `letter=${letter}` : "";
  const url = `${getUrl(
    lang,
    defaultLang
  )}/aunds/wiki?${letterParam}${dynamicParams}`;

  const wikisRes = await fetchWithRetry(url);
  return (await wikisRes.json()) as Promise<AzFilterPostType>;
}

export function dynamicParamUrl(postsPerPage?: number, page?: number) {
  let url;

  if (postsPerPage && page) {
    url = "&per_page=" + postsPerPage + "&page=" + page;
  } else {
    url = "";
  }

  return url;
}

interface AllWikiReturnType {
  allWikis: WikiType[];
  totalPosts: number;
}

export async function getAllWikis(
  taxonomy?: string,
  taxonomyID?: string,
  taxRelation?: "AND" | "OR",
  postsPerPage?: number,
  page?: number,
  lang?: string,
  defaultLang?: string
): Promise<AllWikiReturnType> {
  const dynamicParams = dynamicParamUrl(postsPerPage, page);
  const filterTaxonomies =
    taxonomy && taxonomyID ? `${taxonomy}` + "=" + `${taxonomyID}` : "";

  const url = `${getUrl(
    lang,
    defaultLang
  )}/aunds/encyclopedia?${filterTaxonomies}${dynamicParams}`;

  const pageRes = await fetch(url);

  const totalPosts = Number(pageRes.headers.get("X-WP-Total"));

  const allWikis = await pageRes.json();

  return { allWikis, totalPosts };
}
