module.exports = {
  env: {
    WP_API_URL: process.env.WP_API_URL,
    BUILD_ALL_POST: process.env.BUILD_ALL_POST,
    REVALIDATE_AFTER: process.env.REVALIDATE_AFTER || 120,
    EMAILJS_PUBLIC_KEY: process.env.EMAILJS_PUBLIC_KEY || "placeholder_key",
    LOCALES: process.env.LOCALES,
    DEFAULT_LOCALE: process.env.DEFAULT_LOCALE,
    PRODUCTION_BROWSER_SOURCE_MAPS: process.env.PRODUCTION_BROWSER_SOURCE_MAPS || "false",
  },
  images: {
    //enter the domain or subdomain where you have WordPress installed
    domains: process.env.IMAGES_DOMAINS?.split(","),
    deviceSizes: [375, 450, 640, 768, 850, 1024, 1200, 1400, 1920, 2048],
    imageSizes: [16, 32, 48, 64, 96, 128, 256, 374],
  },
  i18n: {
    locales: ["de"],
    defaultLocale: "de",
  },
  compress: true,
  experimental: {
    scrollRestoration: true,
  },
  staticPageGenerationTimeout: 1000,
  webpack(config) {
    config.module.rules.push({
      test: /\.svg$/,
      issuer: { and: [/\.(js|ts)x?$/] },
      use: ["@svgr/webpack"],
    });
    return config;
  },
  trailingSlash: true,
  productionBrowserSourceMaps: process.env.PRODUCTION_BROWSER_SOURCE_MAPS === "true",
};
