import React, { createContext, useContext, useState, useEffect } from "react";
import { getSearch } from "../hooks/getSearch";
import useUpdateEffect from "../hooks/useUpdateEffect";
import { SearchWikiType } from "../utils/baseTypes";

const SearchContext = createContext<any>({});

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  // Search
  const [searchPosts, setSearchPosts] = useState<SearchWikiType>({
    postsTotal: 0,
    posts: [],
    keyword: null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchPostsPerPage, setSearchPostsPerPage] = useState(12);

  const [loading, setLoading] = useState(false);

  useUpdateEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      if (searchValue !== "") {
        const searchData = await getSearch(searchValue, searchPostsPerPage, 1);
        setSearchPosts(searchData);
      }
      setCurrentPage(1);
      setLoading(false);
    };
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useUpdateEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      if (searchValue !== "") {
        const searchData = await getSearch(
          searchValue,
          searchPostsPerPage,
          currentPage
        );
        setSearchPosts(searchData);
      }
      setLoading(false);
    };

    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <SearchContext.Provider
      value={{
        searchPosts,
        setSearchPosts,
        currentPage,
        setCurrentPage,
        loading,
        searchPostsPerPage,
        setSearchValue,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useSearchContext };
